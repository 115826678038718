<script>
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import axios from 'axios';

export default {
  props:{
    email: [String],
  },
  components: {}
  ,
  data() {
    return {
      componentKey: 0,
      password: "",
    };
  },
  computed:{},
  watch:{},
  validations: {
    email: { required, email },
    password: { required }
  },
  mounted: function(){},
  methods: {
    emit(){
      Swal.close();
      this.$emit('success');
    },

    tryToLogIn() {
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log(this.$v);
        return;
      } else {
        let app = this;

        Swal.fire({
          title: "Validating credential.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });
        
        axios
        .post(
          window.serverPath+'dist/mvw/controllers/users.php',
          {
            request: 'loginUser',
            data: {
              deEmail: this.email,
              dePassword: this.password
            }
          }
        )
        .then(response => {
          if( response.status == 200 ){
            if(response.data.user){
              app.user          = response.data;
              localStorage.setItem( 'user', JSON.stringify(response.data) );

              Swal.close();
              app.emit();
            }else{
              Swal.fire("Ooops!", "🤨 " + response.data.error + " <br>Please try again.", "error");
            }
          }else{
            Swal.fire("Ooops!", "Something is wrong with your credential🤨! Please try again.", "error");
          }
        })
        .catch(function (error) {
          if(
            error.code == "ERR_BAD_RESPONSE"    ||
            error.code == "ERR_NETWORK"
          ){
            Swal.fire("Ooops!", "Something is wrong with our service 😅!<br>Please try again.", "error");
          }
        });
      }
    },

    forceRerenderMap() {
      this.componentKey += 1;
      this.$forceUpdate();
    }
  }
};
</script>

<template :key="componentKey">
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group auth-form-group-custom mb-4">
        <i class="ri-mail-line auti-custom-input-icon"></i>
        <label for="email">Email</label>
        <input
          type="email"
          v-model="email"
          class="form-control"
          id="email"
          placeholder="Enter email"
          :class="{ 'is-invalid': $v.email.$error }"
        />
        <div v-if="$v.email.$error" class="invalid-feedback">
          <span v-if="!$v.email.required">Email is required.</span>
          <span v-if="!$v.email.email">Please enter valid email.</span>
        </div>
      </div>

      <div class="form-group auth-form-group-custom mb-4">
        <i class="ri-lock-2-line auti-custom-input-icon"></i>
        <label for="userpassword">Password</label>
        <input
          v-model="password"
          type="password"
          class="form-control"
          id="userpassword"
          placeholder="Enter password"
          :class="{ 'is-invalid': $v.password.$error }"
        />
        <div
          v-if="!$v.password.required"
          class="invalid-feedback"
        >Password is required.</div>
      </div>

      <div class="mt-4 text-center">
        <button
          class="btn btn-primary w-md waves-effect waves-light"
          type="button"
          @click="tryToLogIn"
        >Log In</button>
      </div>

      <div class="mt-4 text-center">
        <router-link tag="a" to="/forgot-password" class="text-muted">
          <i class="mdi mdi-lock mr-1"></i> Forgot your password?
        </router-link>
      </div>

    </div>
  </div>
</template>